import React, { useState, useEffect } from 'react';
import { post } from '../helpers/api_helper';
import { POST_COMPANY_SETTING } from '../helpers/url_helper';
import Header from '../component/header';
import SideNavbar from '../component/side_navbar';

const CompanyContext = React.createContext();

// component

function Default({ children }) {
    window.scrollTo(0, 0);

    const [companyInfo, SetcompanyInfo] = useState([]);

    useEffect(() => {
        async function get_detaile() {
            var res_data = await post(POST_COMPANY_SETTING, {});
            if (res_data.status) {
                SetcompanyInfo(res_data.data);
            }
        }
        get_detaile();
    }, [])

    const [MenuHide, setMenuHide] = useState('');

    const handleMenu = () => {
        if (MenuHide) {
            setMenuHide('');
        } else {
            setMenuHide('hide_menu');
        }
    }

    return (
        <>
            <CompanyContext.Provider value={companyInfo}>
                <div className={MenuHide}>
                    <Header onChangeHandleMenu={handleMenu} />
                    <div className='main_body'>
                        <SideNavbar />
                        <div className='web_body mt-20 mb-25 pl-20 pr-20'>
                            <div className='container-fluid'>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </CompanyContext.Provider>
        </>
    );
}

export default Default;
export { CompanyContext }
