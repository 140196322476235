import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_PRODUCTION, POST_GET_PRODUCTION_PRODUCT,POST_DELETE_PRODUCT, POST_ADD_EDIT_PRODUCT, POST_GET_PRODUCTION_DETAIL, POST_GET_PRODUCTION_FORM_OPTION } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

import SearchProductModal from './SearchProductModal';
import StockFormModal from './../Stock/StockFormModal';
import ShadeFormModal from './../Shade/ShadeFormModal';
import LocationFormModal from './../Location/LocationFormModal';

const BrandModal = props => {

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'productionId': 0, 'productionDate': Moment(new Date()).format('YYYY-MM-DD'), 'stockId': '', 'remark': '' });
    const [product_inputs, setProductInputs] = useState({'productionDetailId':0 ,'productionId': 0, 'sizeId': '', 'brandId': '', 'itemId': '', 'batch': '', 'shadeId': '0', 'locationId': '0', 'preBox': '0', 'stdBox': '0', 'ecoBox': '0', 'mixBox': '0', 'totalBox': '0' });
    const [, forceUpdate] = useState();
    const [startDate, setStartDate] = useState(new Date());
    
    const [productList, setProductList] = useState([]);
    const [stockList, setStockList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [sizeList, setSizeList] = useState([]);
    const [locationShadeList, setLocationShade] = useState([]);
    const [locationList, setLocation] = useState([]);

    const [totalBox, setTotalBox] = useState({ totalPreBox: 0, totalStdBox: 0, totalEcoBox: 0, totalMixBox: 0, totalBox: 0 });
    const startRef = useRef();

    useEffect(() => {
        async function get_detaile() {
            await get_form_option();
            if (props.editId) {
                var res_data = await post(POST_GET_PRODUCTION_DETAIL, { 'productionId': props.editId }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                    setStartDate(new Date(res_data.detail.productionDate));
                    setProductInputs(inputs => ({ ...product_inputs, ['productionId']: res_data.detail.productionId }));
                    await get_all_product(res_data.detail.productionId);
                } else {
                    toast.error(res_data.message);
                }
            } else {
                setInputs({ 'productionId': 0, 'productionDate': Moment(new Date()).format('YYYY-MM-DD'), 'stockId': '', 'remark': '' });
                setProductList([]);
                setProductInputs({ 'productionDetailId': 0,'productionId': 0, 'sizeId': '', 'brandId': '', 'itemId': '','designName':'' ,'batch': '', 'shadeId': '0', 'locationId': '0', 'preBox': '0', 'stdBox': '0', 'ecoBox': '0', 'mixBox': '0', 'totalBox': '0' })
                setTotalBox({ totalPreBox: 0, totalStdBox: 0, totalEcoBox: 0, totalMixBox: 0, totalBox: 0 })
                setStartDate(new Date());
            }
            setLoader(1);
            window.document.addEventListener('keyup', (event) => {
                if (event.keyCode === 27) {
                    if(
                        !window.$('#searchDesigneModal').is(':visible') && 
                        !window.$('#stockModal').is(':visible') && 
                        !window.$('#shadeModal').is(':visible') && 
                        !window.$('#locationModal').is(':visible')
                    ){
                        window.$('#productionModal').modal('hide');
                        props.call_back();
                    }
                }
            });
        }
        get_detaile();
    }, [props.editId])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_PRODUCTION, form_inputs, {});
            if (res_data.status) {
                setProductInputs(inputs => ({ ...product_inputs, ['productionId']: res_data.data.productionId }));
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    // get_form_option
    const get_form_option = async (event) => {
        var res_data = await post(POST_GET_PRODUCTION_FORM_OPTION, {});
        if (res_data.status) {
            setStockList(res_data.data.stock_by_list);
            setBrandList(res_data.data.brand_list);
            setSizeList(res_data.data.size_list);
            setLocationShade(res_data.data.location_shade_list);
            setLocation(res_data.data.location_list);
        } else {
            toast.error(res_data.message);
        }
    }

    // remove user role
    const AddBrandModal = async (type, event) => {
        if (event.keyCode === 183) {
            if (type === 'stock') {
                window.$('#stockModal').modal({ backdrop: 'static', keyboard: false });
                setTimeout(() => { document.getElementById("stockName").focus(); }, 500);
            }else if(type === 'shade'){
                window.$('#shadeModal').modal({ backdrop: 'static', keyboard: false });
                setTimeout(() => { document.getElementById("shadeName").focus(); }, 500);
            }else if(type === 'location'){
                window.$('#locationModal').modal({ backdrop: 'static', keyboard: false });
                setTimeout(() => { document.getElementById("locationName").focus(); }, 500);
            }
        }
    }

    const setDate = (event) => {
        setStartDate(event);
        var date = new Date(event),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var new_date = [date.getFullYear(), mnth, day].join("-");
        console.log(new_date);
        setInputs(inputs => ({ ...form_inputs, ['productionDate']: new_date }));
    }

    const onKeyDown = (e) => {
        if (e.key === 'Tab') {
            startRef.current.setOpen(false);
        }
    };

    // form submit event
    const get_all_product = async (productionId) => {
        var res_data = await post(POST_GET_PRODUCTION_PRODUCT, { productionId: productionId });
        if (res_data.status) {
            setProductList(res_data.data.product_list);
            setTotalBox(res_data.data.total_box);
        } else {
            toast.error(res_data.message);
        }
    }

    const removeItam = async (productionId,productionDetailId) => {
        var res_data = await post(POST_DELETE_PRODUCT, { productionId: productionId , productionDetailId : productionDetailId});
        if (res_data.status) {
            toast(res_data.message);
            await get_all_product(productionId);
        } else {
            toast.error(res_data.message);
        }
    }

    const editProduct = async (product) => {
       setProductInputs({'productionDetailId': product.productionDetailId, 'productionId': product.productionId, 'sizeId': product.sizeId, 'brandId': product.brandId, 'itemId': product.itemId, 'designName' : product.designName, 'batch': product.batch, 'shadeId': product.shadeId, 'locationId': product.locationId, 'preBox': product.preBox, 'stdBox': product.stdBox, 'ecoBox': product.ecoBox, 'mixBox': product.mixBox, 'totalBox': product.totalBox })
       window.$("#sizeId").focus();
    }

    // form submit event
    const handleSubmitProduct = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_PRODUCT, product_inputs, {});
            if (res_data.status) {
                // toast(res_data.message);
                product_inputs.productionDetailId = 0;
                product_inputs.preBox = 0;
                product_inputs.stdBox = 0;
                product_inputs.ecoBox = 0;
                product_inputs.mixBox = 0;
                product_inputs.itemId = '';
                product_inputs.designName = '';
                setProductInputs(inputs => ({ ...product_inputs, ['totalBox']: 0 }));
                await get_all_product(product_inputs.productionId);
                window.$("#sizeId").focus();
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const handleInputChangeProduct = (event) => {
        event.persist();
        if (event.target.name === 'sizeId' || event.target.name === 'brandId') {
            product_inputs.designName = '';
            product_inputs.itemId = '';
        } else if (event.target.name === 'preBox') {
            product_inputs.totalBox = (parseInt(event.target.value) + parseInt(product_inputs.stdBox) + parseInt(product_inputs.ecoBox) + parseInt(product_inputs.mixBox));
        } else if (event.target.name === 'stdBox') {
            product_inputs.totalBox = (parseInt(event.target.value) + parseInt(product_inputs.preBox) + parseInt(product_inputs.ecoBox) + parseInt(product_inputs.mixBox));
        } else if (event.target.name === 'ecoBox') {
            product_inputs.totalBox = (parseInt(event.target.value) + parseInt(product_inputs.preBox) + parseInt(product_inputs.stdBox) + parseInt(product_inputs.mixBox));
        } else if (event.target.name === 'mixBox') {
            product_inputs.totalBox = (parseInt(event.target.value) + parseInt(product_inputs.preBox) + parseInt(product_inputs.stdBox) + parseInt(product_inputs.ecoBox));
        }
        setProductInputs(inputs => ({ ...product_inputs, [event.target.name]: event.target.value }));
    }

    const OpneModalSearch = () =>{
        // alert()
        window.$('#searchDesigneModal').modal({ backdrop: 'static', keyboard: false });
        setTimeout(() => {
            window.$("#designSearch").focus();
        }, 500);
    }

    const setDesignValue = (value) => {
        product_inputs.itemId = value.itemId;
        product_inputs.designName = value.designName;
        setProductInputs(inputs => ({ ...product_inputs, ['itemId']: value.itemId }));
        setTimeout(() => {
            window.$("#batch").focus();
        }, 500);
    }
    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <div className="modal fade" id="productionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title mt-0" id="exampleModalLabel">Add Stock Master</h5>
                                <small style={{"fontSize": "12px"}}><b>F3 </b> for add new From, Shade and Location</small>
                            </div>
                            <button type="button" onClick={() => { props.call_back() }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="col-sm-3 form-group">
                                        <label htmlFor="">Select Date</label>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={startDate}
                                            onChange={setDate}
                                            ref={startRef} onKeyDown={onKeyDown}
                                            id="productionDate"
                                            className="form-control"
                                            placeholderText="Select Date"
                                            tabIndex={1}
                                            style={{ 'width': '100%' }}
                                        />
                                        {simpleValidator.current.message('date', form_inputs.productionDate, 'required')}
                                    </div>
                                    <div className="col-sm-3 form-group">
                                        <label>From</label>
                                        <select name="stockId" id='stockId' onKeyDown={AddBrandModal.bind(this, 'stock')} tabIndex={2} value={form_inputs.stockId} onChange={handleInputChange} className="form-control" >
                                            <option value="">Self</option>
                                            {stockList && Object.entries(stockList).map(([key, value]) => (
                                                <option key={key} value={value.value}>{value.label}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('from', form_inputs.stockId, 'required')}
                                    </div>
                                    <div className="col-sm-3 form-group">
                                        <label className="form-label text-capitalize">Remark</label>
                                        <input name="remark" id='remark' autoFocus tabIndex={3} value={form_inputs.remark} onChange={handleInputChange} className="form-control" type="text" />
                                    </div>
                                    <div className="col-sm-3 form-group">
                                        <button type='submit' disabled={ButtonDisabled} className="btn btn-sm btn-primary c-btn-primary mt-4">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save</button>
                                    </div>
                                </div>
                            </form>
                            <div className='row mt-3'>
                                <div className='col-sm-12 border table-fixed' >
                                    <table className='table table-small '>
                                        <thead>
                                            <tr>
                                                <th>Sr.</th>
                                                <th>Design</th>
                                                <th>Size</th>
                                                <th>Batch</th>
                                                <th>Shade</th>
                                                <th>Location</th>
                                                <th>PRE-I</th>
                                                <th>STD-II</th>
                                                <th>ECO-III</th>
                                                <th>MIX</th>
                                                <th>Total</th>
                                                <th width="70px"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productList && Object.entries(productList).map(([key, value]) => (
                                                <tr key={key}>
                                                    <td>{++key}</td>
                                                    <td className='text-left'>{value.designName}</td>
                                                    <td>{value.sizeName}</td>
                                                    <td>{value.batch}</td>
                                                    <td>{(value.shadeName) ? value.shadeName : '-'}</td>
                                                    <td>{(value.locationName) ? value.locationName : '-'}</td>
                                                    <td>{value.preBox}</td>
                                                    <td>{value.stdBox}</td>
                                                    <td>{value.ecoBox}</td>
                                                    <td>{value.mixBox}</td>
                                                    <td>{value.totalBox}</td>
                                                    <td>
                                                        <button type='button' className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1 mr-2" onClick={editProduct.bind(this,value)} ><i className="fa fa-pencil" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                        <button type='button' className="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1" onClick={removeItam.bind(this,value.productionId,value.productionDetailId)} ><i className="fa fa-trash-o" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {productList.length < 6 && [...Array(((6 - productList.length)))].map((x, i) => <>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className='text-left'></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan="6" className='text-right'><b>Total</b></th>
                                                <th>{totalBox.totalPreBox}</th>
                                                <th>{totalBox.totalStdBox}</th>
                                                <th>{totalBox.totalEcoBox}</th>
                                                <th>{totalBox.totalMixBox}</th>
                                                <th>{totalBox.totalBox}</th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            {product_inputs.productionId > 0 && <form onSubmit={handleSubmitProduct}>
                                <div className="row mt-3">
                                    <div className="col-sm-3 form-group">
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label className='mt-2'>Size</label>
                                            </div>
                                            <div className='col-8'>
                                                <select name="sizeId" id='sizeId' tabIndex={5} value={product_inputs.sizeId} onChange={handleInputChangeProduct} className="form-control" >
                                                    <option value="">Size</option>
                                                    {sizeList && Object.entries(sizeList).map(([key, value]) => (
                                                        <option key={key} value={value.value}>{value.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-4'>
                                                <label className='mt-2'>Brand</label>
                                            </div>
                                            <div className='col-8'>
                                                <select name="brandId" id='brandId' tabIndex={6} value={product_inputs.brandId} onChange={handleInputChangeProduct} className="form-control" >
                                                    <option value="">Brand</option>
                                                    {brandList && Object.entries(brandList).map(([key, value]) => (
                                                        <option key={key} value={value.value}>{value.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-4'>
                                                <label className='mt-2'>Design</label>
                                            </div>
                                            <div className='col-8'>
                                                <input name="itemId" id='itemId' onFocus={OpneModalSearch} tabIndex={7} value={product_inputs.itemId} onChange={handleInputChangeProduct} readOnly className="form-control none" type="hidden" />
                                                <input name="designName" id='designName' onFocus={OpneModalSearch} tabIndex={7} value={product_inputs.designName} onChange={handleInputChangeProduct} readOnly className="form-control" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 form-group">
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label className='mt-2'>Batch</label>
                                            </div>
                                            <div className='col-8'>
                                                <input name="batch" id='batch' tabIndex={8} value={product_inputs.batch} onChange={handleInputChangeProduct} className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-4'>
                                                <label className='mt-2'>Shade</label>
                                            </div>
                                            <div className='col-8'>
                                                <select name="shadeId" id='shadeId' onKeyDown={AddBrandModal.bind(this, 'shade')} tabIndex={9} value={product_inputs.shadeId} onChange={handleInputChangeProduct} className="form-control" >
                                                    <option value="0">(Abort)</option>
                                                    {locationShadeList && Object.entries(locationShadeList).map(([key, value]) => (
                                                        <option key={key} value={value.value}>{value.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-4'>
                                                <label className='mt-2'>Location</label>
                                            </div>
                                            <div className='col-8'>
                                                <select name="locationId" id='locationId' onKeyDown={AddBrandModal.bind(this, 'location')} tabIndex={10} value={product_inputs.locationId} onChange={handleInputChangeProduct} className="form-control" >
                                                    <option value="0">(Abort)</option>
                                                    {locationList && Object.entries(locationList).map(([key, value]) => (
                                                        <option key={key} value={value.value}>{value.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <tbody className='table1'>
                                            <tr>
                                                <td>PRE-I</td>
                                                <td>STD-II</td>
                                                <td>ECO-III</td>
                                                <td>MIX</td>
                                                <td>Total</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <input name="preBox" tabIndex={11} value={product_inputs.preBox} onChange={handleInputChangeProduct} className="form-control" type="text" />
                                                </td>
                                                <td>
                                                    <input name="stdBox" tabIndex={12} value={product_inputs.stdBox} onChange={handleInputChangeProduct} className="form-control" type="text" />
                                                </td>
                                                <td>
                                                    <input name="ecoBox" tabIndex={13} value={product_inputs.ecoBox} onChange={handleInputChangeProduct} className="form-control" type="text" />
                                                </td>
                                                <td>
                                                    <input name="mixBox" tabIndex={14} value={product_inputs.mixBox} onChange={handleInputChangeProduct} className="form-control" type="text" />
                                                </td>
                                                <td>
                                                    <input name="totalBox" readOnly tabIndex={15} value={product_inputs.totalBox} onChange={handleInputChangeProduct} className="form-control" type="text" />
                                                </td>
                                            </tr>
                                        </tbody>
                                        <div className='text-right'>
                                            <input type='submit' tabIndex={16} className='btn btn-sm btn-primary c-btn-primary mt-3' value="save" />
                                        </div>
                                    </div>
                                </div>
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
            <SearchProductModal product_inputs={product_inputs} call_back={(select) => { setDesignValue(select)}}/>
            <StockFormModal call_back={() => { get_form_option(); window.$("#stockId").focus();}}/>
            <ShadeFormModal call_back={() => { get_form_option(); window.$("#shadeId").focus();}}/>
            <LocationFormModal call_back={() => { get_form_option(); window.$("#locationId").focus();}}/>
        </>
    );
}
export default withRouter(BrandModal)
