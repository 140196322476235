import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from "react-router-dom";
import { get } from '../../helpers/api_helper';
import { GET_DASHBOARD_DATA } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { CompanyContext } from '../../layouts/Default';
import { Helmet } from "react-helmet";

const Dashboard = props => {
    const [totalUser, SetTotalUser] = useState(0);
    const { company_name } = useContext(CompanyContext);

    useEffect(() => {
        async function get_detaile() {
            await get_service();
        }
        get_detaile();
    }, [])

    const get_service = async () => {
        var res_data = await get(GET_DASHBOARD_DATA, {});
        if (res_data.status) {
            SetTotalUser(res_data.total_user)
        } else {
            toast.error(res_data.message);
        }
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{(company_name) ? company_name : ''} | Dashboard</title>
            </Helmet>
            <div className='row'>
                <div className='col-sm-12'>
                    <h5>Welcome To {company_name}</h5>
                </div>
            </div>
            <div className='row mt-15'>
                {/* <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6>Total Designer</h6>
                            <h6>{totalDesigner}</h6>
                        </div>
                    </div>
                </div> */}
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6>Total Customer</h6>
                            <h6>{totalUser}</h6>
                        </div>
                    </div>
                </div>
                {/* <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6>Total Pending Complain</h6>
                            <h6>{totalComplain}</h6>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default withRouter(Dashboard);
