import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_BRAND, POST_GET_BRAND_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

const BrandModal = props => {

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'brandId': 0, 'brandName': '' });
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.editId) {
                var res_data = await post(POST_GET_BRAND_DETAIL, { 'brandId': props.editId }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                }
            } else {
                setInputs({ 'brandId': 0, 'brandName': '' });
            }
            setLoader(1);

            window.document.addEventListener('keyup', (event) => {
                if (event.keyCode === 27) {
                    window.$('#brandModal').modal('hide');
                }
            });
        }
        get_detaile();
    }, [props.editId])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_BRAND, form_inputs, {});
            if (res_data.status) {
                window.$('#brandModal').modal('hide');
                if (!props.editId) {
                    setInputs({ 'brandId': 0, 'brandName': '' });
                }
                props.call_back();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <div className="modal fade" id="brandModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Brand Master</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form className="av-invalid" onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Brand Name</label>
                                            <input name="brandName" id="brandName" autoFocus tabIndex={1} value={form_inputs.brandName} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('brand name', form_inputs.brandName, 'required')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel (Esc)</button>
                                <button type='submit' tabIndex={2} disabled={ButtonDisabled} className="btn btn-sm btn-primary c-btn-primary">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(BrandModal)
