const moment = require("moment");

const getToken = () => {
    return localStorage.getItem('token') || null;
}

const getUserDetail = () => {
    return JSON.parse(localStorage.getItem('user_detail'));
}

const getUserId = () => {
    return (localStorage.getItem('user_detail'))? JSON.parse(localStorage.getItem('user_detail'))['user_id'] : 0;
}

const getUserType = () => {
    if(localStorage.getItem('user_detail')){
        return JSON.parse(localStorage.getItem('user_detail'))['user_role_id'];
    }else{
        return 0;
    }
}

const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_detail');
}

const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user_detail', JSON.stringify(user));
}

const is_login = () => {
    if (localStorage.getItem('token')) {
        return true;
    } else {
        return false;
    }
}

const amountFormat = (amount) => {
    return parseFloat(amount).toFixed(2);
}

const img_view = (img , width = '' , height = '') => {
    if(img){
        return process.env.REACT_APP_IMG_URL+'img?img='+img+'&w='+width+'&h='+height
    }else{
        return "https://dummyimage.com/" + width + "X" + height + "/#CDCFC/000000.jpg";
    }
}

const video_download = (product_id = 0,episod_id = 0) => {
    return process.env.REACT_APP_IMG_URL+'img/download?p_id='+product_id+'&e_id='+episod_id
}

const file_size_format = (bytes, decimals = 1) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    var dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    if(i <= 2){
        dm = 0;
    }
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const arrayColumn = (column_array, column) => {
    const arrayColumn = (arr, n) => arr.map(x => x[n]);
    return arrayColumn(column_array, column);
}

//multi_select_value_array
const MultiSelectValueArray = (option_array = [], valueListe = '') => {
    valueListe = valueListe.split(',');
    var select_option = [];
    if(valueListe.length){
        for(var value of valueListe){
            var option = option_array.filter((item) => {
                return item.value === parseInt(value);
            })
            if(option){
                select_option.push(option[0]);
            }
        }
    }
    return select_option;
}

// get curant time utc 
const utc_to_date_time = (time, format = "DD-MM-YYYY HH:mm:ss") => {
    time = new Date(time);
    return moment(time).format(format);
}

// get curant time utc 
const WeightFormat = (Weight) => {
    if(Weight < 1000){
        return Weight+'Kg';
    }else{
        return (Weight / 1000).toFixed(1)+'Ton';
    }
}

export {WeightFormat,MultiSelectValueArray,utc_to_date_time,arrayColumn,file_size_format, video_download, img_view,amountFormat, getUserType,getToken, removeUserSession, setUserSession, is_login,getUserDetail, getUserId };