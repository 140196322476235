
// auth
export const POST_COMPANY_SETTING = "/auth/get-company-setting"
export const POST_LOGIN = "/auth/login"
export const POST_VERIFY_OTP = "/auth/verify-otp"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"

//user
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"
export const POST_USER_LIST = process.env.REACT_APP_API_URL+"user/get-all-user"
export const DELETE_USER = "/user/delete"
export const POST_UPDATE_USER_STATUS = "/user/update-status"
export const POST_GET_USER_DETAIL = "/user/detail"
export const POST_ADD_EDIT_USER = "/user/add-edit"
export const GET_COUNTRY = "/user/get-country"
export const GET_STATE = "/user/get-country-based-states"
export const GET_CITY = "/user/get-states-based-city"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"
export const POST_IMPORT_STOCK = "/setting/import-stock"

//category
export const POST_CATEGORY_LIST = process.env.REACT_APP_API_URL+"category/get-all-category"
export const DELETE_CATEGORY = "/category/delete"
export const POST_UPDATE_CATEGORY_STATUS = "/category/update-status"
export const POST_ADD_EDIT_CATEGORY = "/category/add-edit"
export const POST_GET_CATEGORY_DETAIL = "/category/detail"

// brand
export const POST_BRAND_LIST = process.env.REACT_APP_API_URL+"brand/get-all-brand"
export const DELETE_BRAND = "/brand/delete"
export const POST_UPDATE_BRAND_STATUS = "/brand/update-status"
export const POST_ADD_EDIT_BRAND = "/brand/add-edit"
export const POST_GET_BRAND_DETAIL = "/brand/detail"

// size
export const POST_SIZE_LIST = process.env.REACT_APP_API_URL+"size/get-all-size"
export const DELETE_SIZE = "/size/delete"
export const POST_UPDATE_SIZE_STATUS = "/size/update-status"
export const POST_ADD_EDIT_SIZE = "/size/add-edit"
export const POST_GET_SIZE_DETAIL = "/size/detail"

// color
export const POST_COLOR_LIST = process.env.REACT_APP_API_URL+"color/get-all-color"
export const DELETE_COLOR = "/color/delete"
export const POST_UPDATE_COLOR_STATUS = "/color/update-status"
export const POST_ADD_EDIT_COLOR = "/color/add-edit"
export const POST_GET_COLOR_DETAIL = "/color/detail"

// punch
export const POST_PUNCH_LIST = process.env.REACT_APP_API_URL+"punch/get-all-punch"
export const DELETE_PUNCH = "/punch/delete"
export const POST_UPDATE_PUNCH_STATUS = "/punch/update-status"
export const POST_ADD_EDIT_PUNCH = "/punch/add-edit"
export const POST_GET_PUNCH_DETAIL = "/punch/detail"

// item
export const POST_ITEM_LIST = process.env.REACT_APP_API_URL+"item/get-all-item"
export const DELETE_ITEM = "/item/delete"
export const POST_UPDATE_ITEM_STATUS = "/item/update-status"
export const POST_ADD_EDIT_ITEM = "/item/add-edit"
export const POST_GET_ITEM_DETAIL = "/item/detail"
export const POST_GET_ITEM_FORM_OPTION = "/item/get-form-option"

//location
export const POST_LOCATION_LIST = process.env.REACT_APP_API_URL+"location/get-all-location"
export const DELETE_LOCATION = "/location/delete"
export const POST_UPDATE_LOCATION_STATUS = "/location/update-status"
export const POST_ADD_EDIT_LOCATION = "/location/add-edit"
export const POST_GET_LOCATION_DETAIL = "/location/detail"

//location_shade
export const POST_SHADE_LIST = process.env.REACT_APP_API_URL+"shade/get-all-shade"
export const DELETE_SHADE = "/shade/delete"
export const POST_UPDATE_SHADE_STATUS = "/shade/update-status"
export const POST_ADD_EDIT_SHADE = "/shade/add-edit"
export const POST_GET_SHADE_DETAIL = "/shade/detail"

// stock
export const POST_STOCK_LIST = process.env.REACT_APP_API_URL+"stock-by/get-all-stock"
export const DELETE_STOCK = "/stock-by/delete"
export const POST_UPDATE_STOCK_STATUS = "/stock-by/update-status"
export const POST_ADD_EDIT_STOCK = "/stock-by/add-edit"
export const POST_GET_STOCK_DETAIL = "/stock-by/detail"

// production
export const POST_PRODUCTION_LIST = process.env.REACT_APP_API_URL+"production/get-all-production"
export const DELETE_PRODUCTION = "/production/delete"
export const POST_ADD_EDIT_PRODUCTION = "/production/add-edit"
export const POST_GET_PRODUCTION_DETAIL = "/production/detail"
export const POST_GET_PRODUCTION_FORM_OPTION = "/production/get-form-option"
export const POST_ADD_EDIT_PRODUCT = "/production/add-edit-product"
export const POST_GET_PRODUCTION_PRODUCT = "/production/get-product"
export const POST_DELETE_PRODUCT = "/production/delete-product"
export const POST_SEARCH_PRODUCT = "/production/search-product"

// order
export const POST_ORDER_LIST = process.env.REACT_APP_API_URL+"order/get-all-order"
export const DELETE_ORDER = "/order/delete"
export const POST_ADD_EDIT_ORDER = "/order/add-edit"
export const POST_GET_ORDER_FORM_OPTION = "/order/get-form-option"
export const POST_GET_ORDER_DETAIL = "/order/detail"
export const POST_GET_ORDER_PRODUCT = "/order/get-orders"
export const POST_ADD_EDIT_PRODUCT_ORDER = "/order/add-edit-product"
export const POST_SELECT_ITEM_PRODUCT = "/order/get-item"
export const POST_DELETE_ORDER_PRODUCT = "/order/delete-product"
export const POST_CONVER_ORDER_TO_DISPATCHED = "/order/dispatched"

export const POST_DISPATCH_LIST = process.env.REACT_APP_API_URL+"dispatched/get-all-dispatched"
export const DELETE_DISPATCH = "/dispatched/delete"

// report
export const POST_GET_FILTER_OPTION = "report/get-filter-option"
export const POST_STOCK_REPORT_LIST = process.env.REACT_APP_API_URL+"report/get-all-stock"

//admin
export const POST_ADMIN_USER_LIST = process.env.REACT_APP_API_URL+"admin/get-all-user"
export const DELETE_ADMIN_USER = "/admin/delete"
export const POST_UPDATE_ADMIN_USER_STATUS = "/admin/update-status"
export const POST_ADD_EDIT_ADMIN_USER = "/admin/add-edit"
export const POST_GET_ADMIN_USER_DETAIL = "/admin/detail"

