import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom"
import { post } from '../../helpers/api_helper';
import { POST_STOCK_REPORT_LIST, POST_GET_FILTER_OPTION } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import { CompanyContext } from '../../layouts/Default';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

const TagsList = props => {
    const { company_name } = useContext(CompanyContext);
    const [brandList, SetBrandList] = useState([]);
    const [categoriesList, SetCategoriesList] = useState([]);
    const [sizeList, SetSizeList] = useState([]);
    const [shadeList, SetShadeList] = useState([]);
    const [form_inputs, setInputs] = useState({ 'brandId': '0', 'categoryId': '0', 'sizeId': '0', 'shadeId': '0' });


    useEffect(async () => {
        get_list();
        var res_data = await post(POST_GET_FILTER_OPTION, {});
        if (res_data.status) {
            SetBrandList(res_data.data.brand_list);
            SetCategoriesList(res_data.data.categories_list);
            SetSizeList(res_data.data.size_list);
            SetShadeList(res_data.data.shade_list);
        } else {
            toast.error(res_data.message);
        }
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_STOCK_REPORT_LIST + "?brandId=" + form_inputs.brandId + "&categoryId=" + form_inputs.categoryId + "&sizeId=" + form_inputs.sizeId + "&shadeId=" + form_inputs.shadeId,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "stockId" },
                    { "data": "designName" },
                    { "data": "brandName" },
                    { "data": "categoryName" },
                    { "data": "sizeName" },
                    { "data": "shadeName" },
                    { "data": "locationName" },
                    { "data": "batch" },
                    { "data": "preBox" },
                    { "data": "stdBox" },
                    { "data": "ecoBox" },
                    { "data": "mixBox" },
                    { "data": "totalBox" },
                ]
            });
        });
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{(company_name) ? company_name : ''} | Stock</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Stock</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {/* <button type="button" onClick={AddSizeModel.bind(this,0)} className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add (F2)</button> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="row mb-4">
                                <div className="col-sm-2">
                                    <select name="brandId" value={form_inputs.brandId} onChange={handleInputChange} className="form-control" >
                                        <option value="0">All BrandId</option>
                                        {brandList && Object.entries(brandList).map(([key, value]) => (
                                            <option key={key} value={value.value}>{value.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-2">
                                    <select name="categoryId" value={form_inputs.categoryId} onChange={handleInputChange} className="form-control" >
                                        <option value="0">All Category</option>
                                        {categoriesList && Object.entries(categoriesList).map(([key, value]) => (
                                            <option key={key} value={value.value}>{value.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-2">
                                    <select name="sizeId" value={form_inputs.sizeId} onChange={handleInputChange} className="form-control" >
                                        <option value="0">All Size</option>
                                        {sizeList && Object.entries(sizeList).map(([key, value]) => (
                                            <option key={key} value={value.value}>{value.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-2">
                                    <select name="shadeId" value={form_inputs.shadeId} onChange={handleInputChange} className="form-control" >
                                        <option value="0">All Shade</option>
                                        {shadeList && Object.entries(shadeList).map(([key, value]) => (
                                            <option key={key} value={value.value}>{value.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-2">
                                    <button type="button" onClick={get_list} className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 mr-2 c-btn-primary text-white">Applay</button>
                                    <span className='dropdown print-dropdown'>
                                        <button type="button" className="btn btn-sm btn-info pt-1 pb-1 pl-2 pr-2 mr-2" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"><i className="fa fa-print" style={{ 'fontSize': '16px' }} aria-hidden="true"></i></button>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <a href={`${process.env.REACT_APP_PDF_URL}pdf/report/stock-design?brandId=${form_inputs.brandId}&categoryId=${form_inputs.categoryId}&sizeId=${form_inputs.sizeId}&shadeId=${form_inputs.shadeId}`} target="_blank" className="dropdown-item">Print (Design)</a>
                                            <a href={`${process.env.REACT_APP_PDF_URL}pdf/report/stock-shade-loc?brandId=${form_inputs.brandId}&categoryId=${form_inputs.categoryId}&sizeId=${form_inputs.sizeId}&shadeId=${form_inputs.shadeId}`} target="_blank" className="dropdown-item">Print (Shade-Loc)</a>
                                            <a href={`${process.env.REACT_APP_PDF_URL}pdf/report/stock-batch-shade-loc?brandId=${form_inputs.brandId}&categoryId=${form_inputs.categoryId}&sizeId=${form_inputs.sizeId}&shadeId=${form_inputs.shadeId}`} target="_blank" className="dropdown-item">Print (Batch-Shade-Loc)</a>
                                            <a href={`${process.env.REACT_APP_PDF_URL}pdf/report/stock-design?brandId=${form_inputs.brandId}&categoryId=${form_inputs.categoryId}&sizeId=${form_inputs.sizeId}&shadeId=${form_inputs.shadeId}&image=true`} target="_blank" className="dropdown-item">Print (Image-Design)</a>
                                            <a href={`${process.env.REACT_APP_PDF_URL}pdf/report/stock-shade-loc?brandId=${form_inputs.brandId}&categoryId=${form_inputs.categoryId}&sizeId=${form_inputs.sizeId}&shadeId=${form_inputs.shadeId}&image=true`} target="_blank" className="dropdown-item">Print (Image-Shade-Loc)</a>
                                            <a href={`${process.env.REACT_APP_PDF_URL}pdf/report/stock-batch-shade-loc?brandId=${form_inputs.brandId}&categoryId=${form_inputs.categoryId}&sizeId=${form_inputs.sizeId}&shadeId=${form_inputs.shadeId}&image=true`} target="_blank" className="dropdown-item">Print (Image-Batch-Shade-Loc)</a>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className="mt-2 mb-5">
                                <table id="dataTableExample" className="table table-striped  table-align-center">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Design</td>
                                            <td>Brand</td>
                                            <td>Category</td>
                                            <td>Size</td>
                                            <td>Shade</td>
                                            <td>Location</td>
                                            <td>Batch</td>
                                            <td>PRE-I</td>
                                            <td>STD-II</td>
                                            <td>ECO-III</td>
                                            <td>MIX</td>
                                            <td>Total Box</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(TagsList)
