import React, { useCallback,useState, useEffect, useContext } from "react";
import {  withRouter } from "react-router-dom"
import { post, del } from '../../helpers/api_helper';
import { POST_PUNCH_LIST, DELETE_PUNCH, POST_UPDATE_PUNCH_STATUS } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import { CompanyContext } from '../../layouts/Default';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

import PunchFormModal from './PunchFormModal';


const TagsList = props => {
    const { company_name } = useContext(CompanyContext);
    const [PunchId, SetPunchId] = useState(0);

    const handleKeyPress = useCallback((event) => {
        if (event.keyCode === 182) {
            AddPunchModel(0);
        }
    }, []);

    useEffect(() => {
        get_list();
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_PUNCH_LIST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [2, 3] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "punchId" },
                    { "data": "punchName" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.punchStatus === 'Active' && <button onClick={UpdateStatus.bind(this, data.punchId, data.punchStatus)} className="border-0 badge badge-success pt-1 pb-1"  >Active</button>}
                                {data.punchStatus === 'Deactive' && <button onClick={UpdateStatus.bind(this, data.punchId, data.punchStatus)} className="border-0 badge badge-danger pt-1 pb-1" >Deactive</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={AddPunchModel.bind(this,data.punchId)} ><i className="fa fa-pencil" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                <button className="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { SetPunchId(data.punchId); }} data-toggle="modal" data-target="#deleteModal" ><i className="fa fa-trash-o" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_PUNCH + "?punchId=" + PunchId);
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }

    //UpdateStatus
    const UpdateStatus = async (punchId, status) => {
        var res_data = await post(POST_UPDATE_PUNCH_STATUS, { punchId: punchId, status: status });
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }

    // remove user role
    const AddPunchModel = async (id = 0) => {
        window.$('#punchModal').modal({ backdrop: 'static', keyboard: false });
        SetPunchId(id);
        setTimeout(() => {
            window.$("#punchName").focus();
        }, 500);
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{(company_name) ? company_name : ''} | Punch List</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Punch List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <button type="button" onClick={AddPunchModel.bind(this,0)} className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add (F2)</button>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <table id="dataTableExample" className="table table-striped  table-align-center">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Name</td>
                                            <td>Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this record?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                                <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PunchFormModal call_back={get_list} editId={PunchId}/>
        </>
    );
}
export default withRouter(TagsList)
