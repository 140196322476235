import React, { useCallback,useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { img_view } from '../../helpers/Helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_ITEM, POST_GET_ITEM_DETAIL, POST_GET_ITEM_FORM_OPTION } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import BrandFormModal from './../Brand/BrandFormModal';
import CategoryFormModal from './../Categories/CategoryFormModal';
import ColorFormModal from './../Color/ColorFormModal';
import SizeFormModal from './../Size/SizeFormModal';
import PunchFormModal from './../Punch/PunchFormModal';


const BrandModal = props => {

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'itemId': 0, 'brandId': '0', 'categoryId': '0', 'sizeId': '0', 'designName': '', 'baseColorId': '0', 'punchId': '0', 'concept': '', 'weightPerBox': '0', 'itemImage': '', 'itemImageUrl': '' });
    const [, forceUpdate] = useState();
    const [ColorList, SetColorList] = useState([]);
    const [BrandList, SetBrandList] = useState([]);
    const [CategoriesList, SetCategoriesList] = useState([]);
    const [PunchList, SetPunchList] = useState([]);
    const [SizeList, SetSizeList] = useState([]);
    
    useEffect(() => {
        async function get_detaile() {
            await get_form_option();
            if (props.editId) {
                var res_data = await post(POST_GET_ITEM_DETAIL, { 'itemId': props.editId }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                }
            } else {
                setInputs({ 'itemId': 0, 'brandId': '', 'categoryId': '', 'sizeId': '', 'designName': '', 'baseColorId': '0', 'punchId': '0', 'concept': '', 'weightPerBox': '0', 'itemImage': '', 'itemImageUrl': '' });

            }

            window.document.addEventListener('keyup', (event) => {
                if (
                    event.keyCode === 27
                    && !window.$('#brandModal').is(':visible')
                    && !window.$('#categoryModal').is(':visible')
                    && !window.$('#sizeModal').is(':visible')
                    && !window.$('#colorModal').is(':visible')
                    && !window.$('#punchModal').is(':visible')
                ) {
                    window.$('#itemModal').modal('hide');
                }
                if(window.$('#brandModal').is(':visible')){
                    setTimeout(() => { window.$("#brandId").focus(); }, 500);
                }else if(window.$('#categoryModal').is(':visible')){
                    setTimeout(() => { window.$("#categoryId").focus(); }, 500);
                }else if(window.$('#sizeModal').is(':visible')){
                    setTimeout(() => { window.$("#sizeId").focus(); }, 500);
                }else if(window.$('#colorModal').is(':visible')){
                    setTimeout(() => { window.$("#baseColorId").focus(); }, 500);
                }else if(window.$('#punchModal').is(':visible')){
                    setTimeout(() => { window.$("#punchId").focus(); }, 500);
                }
            });
            setLoader(1);
        }
        get_detaile();
    }, [props.editId])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_ITEM, form_inputs, {});
            if (res_data.status) {
                window.$('#itemModal').modal('hide');
                if (!props.editId) {
                    setInputs({ 'itemId': 0, 'brandId': '0', 'categoryId': '0', 'sizeId': '0', 'designName': '', 'baseColorId': '0', 'punchId': '0', 'concept': '', 'weightPerBox': '', 'itemImage': '', 'itemImageUrl': '' });
                }
                props.call_back();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // get_form_option
    const get_form_option = async (event) => {
        var res_data = await post(POST_GET_ITEM_FORM_OPTION, {});
        if (res_data.status) {
            SetColorList(res_data.data.color_list);
            SetBrandList(res_data.data.brand_list);
            SetCategoriesList(res_data.data.categories_list);
            SetPunchList(res_data.data.punch_list);
            SetSizeList(res_data.data.size_list);
        } else {
            toast.error(res_data.message);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'itemImage') {
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function () {
                setInputs(inputs => ({ ...form_inputs, [event.target.name]: reader.result }));
            }
            reader.readAsDataURL(file);
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // remove user role
    const AddBrandModal = async (type, event) => {
        if (event.keyCode === 183) {
            if (type === 'brand') {
                window.$('#brandModal').modal({ backdrop: 'static', keyboard: false });
                setTimeout(() => { document.getElementById("brandName").focus(); }, 500);
            } else if (type === 'category') {
                window.$('#categoryModal').modal({ backdrop: 'static', keyboard: false });
                setTimeout(() => { document.getElementById("categoryName").focus(); }, 500);
            } else if (type === 'size') {
                window.$('#sizeModal').modal({ backdrop: 'static', keyboard: false });
                setTimeout(() => { document.getElementById("sizeName").focus(); }, 500);
            } else if (type === 'color') {
                window.$('#colorModal').modal({ backdrop: 'static', keyboard: false });
                setTimeout(() => { document.getElementById("baseColorName").focus(); }, 500);
            } else if (type === 'punch') {
                window.$('#punchModal').modal({ backdrop: 'static', keyboard: false });
                setTimeout(() => { document.getElementById("punchName").focus(); }, 500);
            }
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <div className="modal fade" id="itemModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title" id="exampleModalLabel">Itme Master</h5>
                                <small style={{ 'fontSize': '12px' }}><b>F3 </b> for add new Brand, Category, Size, Color and Punch</small>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form className="av-invalid" onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Select Brand</label>
                                            <select name="brandId" id="brandId" onKeyDown={AddBrandModal.bind(this, 'brand')} tabIndex={1} value={form_inputs.brandId} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Brand</option>
                                                {BrandList && Object.entries(BrandList).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('brand', form_inputs.brandId, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label>Select Category</label>
                                            <select name="categoryId" id='categoryId' onKeyDown={AddBrandModal.bind(this, 'category')} tabIndex={2} value={form_inputs.categoryId} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Category</option>
                                                {CategoriesList && Object.entries(CategoriesList).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('category', form_inputs.categoryId, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label>Select Size</label>
                                            <select name="sizeId" id='sizeId' onKeyDown={AddBrandModal.bind(this, 'size')} tabIndex={3} value={form_inputs.sizeId} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Size</option>
                                                {SizeList && Object.entries(SizeList).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('size', form_inputs.sizeId, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label>Select Base Color</label>
                                            <select name="baseColorId" id='baseColorId' onKeyDown={AddBrandModal.bind(this, 'color')} tabIndex={4} value={form_inputs.baseColorId} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Base Color</option>
                                                {ColorList && Object.entries(ColorList).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Select Punch</label>
                                            <select name="punchId" id='punchId' onKeyDown={AddBrandModal.bind(this, 'punch')} tabIndex={5} value={form_inputs.punchId} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Punch</option>
                                                {PunchList && Object.entries(PunchList).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">concept</label>
                                            <input name="concept" id="concept" tabIndex={6} value={form_inputs.concept} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Design Name</label>
                                            <input name="designName" tabIndex={7} value={form_inputs.designName} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('design name', form_inputs.designName, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Weight Per Box</label>
                                            <input name="weightPerBox" tabIndex={8} value={form_inputs.weightPerBox} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label text-capitalize" tabIndex={9} htmlFor='Image'>
                                                        <img src={img_view(form_inputs.itemImageUrl, 120, 120)} style={{ "maxWidth": "250px", "maxHeight": "120px" }} />
                                                        <input name="itemImage" id='Image' type="file" tabIndex={9} onChange={handleInputChange} className="form-control node" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel (Esc)</button>
                                <button type='submit' disabled={ButtonDisabled} className="btn btn-sm btn-primary c-btn-primary">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <BrandFormModal call_back={get_form_option} editId={0} />
            <CategoryFormModal call_back={get_form_option} editId={0} />
            <ColorFormModal call_back={get_form_option} editId={0} />
            <SizeFormModal call_back={get_form_option} editId={0} />
            <PunchFormModal call_back={get_form_option} editId={0} />
        </>
    );
}
export default withRouter(BrandModal)
