import React, { useState,useEffect } from 'react';
import { Route } from 'react-router';
import { post } from '../helpers/api_helper';
import { POST_COMPANY_SETTING } from '../helpers/url_helper';
const CompanyContext = React.createContext();


const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
    const [companyInfo, SetcompanyInfo] = useState([]);

    useEffect(() => {
        async function get_detaile() {
            var res_data = await post(POST_COMPANY_SETTING, {});
            if (res_data.status) {
                SetcompanyInfo(res_data.data);
            }
        }
        get_detaile();
    }, [])

    return <CompanyContext.Provider value={companyInfo}>
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
        </CompanyContext.Provider>
};

export default AppRoute;
export { CompanyContext }
