import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';

import { BrowserRouter } from 'react-router-dom';

import Default from './layouts/Default';
import Full from './layouts/Full';

// pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";

//Admin
import AdminList from "./pages/Admin/AdminList";
import AdminForm from "./pages/Admin/AdminForm";

//User
import CustomerList from "./pages/Customer/CustomerList";

//Category
import CategoryList from "./pages/Categories/CategoryList";

//Brand
import BrandList from "./pages/Brand/BrandList";

// size
import SizeList from "./pages/Size/SizeList";

// color
import ColorList from "./pages/Color/ColorList";

//punch
import PunchList from "./pages/Punch/PunchList";

//Item Master
import ItemMasterList from "./pages/ItemMaster/ItemMasterList";

// LocationList
import LocationList from "./pages/Location/LocationList";

//shade
import ShadeList from "./pages/Shade/ShadeList";

//Stock
import StockList from "./pages/Stock/StockList";

//ProductionList
import ProductionList from "./pages/Production/ProductionList";

//order
import OrderList from "./pages/Order/OrderList";
import DispatchedList from "./pages/Dispatched/DispatchedList";

import StockReportList from "./pages/Report/StockList";

// setting
import Setting from "./pages/Setting/Setting";
import EmailSetting from "./pages/Setting/EmailSetting";
import CommonSetting from "./pages/Setting/CommonSetting";
import ImportStock from "./pages/Setting/ImportStock";




function App() {
    return (
        <>
        <BrowserRouter>
            <Switch>
                <AppRoute exact path="/login" component={Login} layout={Full} />
                <AppRoute exact path="/logout" component={Logout} layout={Full} />
                <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Full} />
                
                <UserRoute exact path="/change-password" component={ChangePassword} layout={Default} />

                {/* admin */}
                <UserRoute exact path="/admin" component={AdminList} layout={Default} />
                <UserRoute exact path="/admin/add" component={AdminForm} layout={Default} />
                <UserRoute exact path="/admin/edit/:id?" component={AdminForm} layout={Default} />
                
                {/* user */}
                <UserRoute exact path="/customer" component={CustomerList} layout={Default} />

                {/* categories */}
                <UserRoute exact path="/categories" component={CategoryList} layout={Default} />
                
                {/* Brand */}
                <UserRoute exact path="/brand" component={BrandList} layout={Default} />
               
                {/* Size */}
                <UserRoute exact path="/size" component={SizeList} layout={Default} />

                {/* Color */}
                <UserRoute exact path="/base-color" component={ColorList} layout={Default} />

                {/* Punch */}
                <UserRoute exact path="/punch" component={PunchList} layout={Default} />

                {/* Item Master */}
                <UserRoute exact path="/item-master" component={ItemMasterList} layout={Default} />

                {/* LocationList */}
                <UserRoute exact path="/location" component={LocationList} layout={Default} />

                {/* Shade */}
                <UserRoute exact path="/shade" component={ShadeList} layout={Default} />

                {/* Stock */}
                <UserRoute exact path="/stock" component={StockList} layout={Default} />

                {/* Production */}
                <UserRoute exact path="/production" component={ProductionList} layout={Default} />

                {/* OrderList */}
                <UserRoute exact path="/order" component={OrderList} layout={Default} />
                <UserRoute exact path="/dispatched" component={DispatchedList} layout={Default} />

                <UserRoute exact path="/stock-view" component={StockReportList} layout={Default} />

                {/* Setting */}
                <UserRoute exact path="/setting" component={Setting} layout={Default} />
                <UserRoute exact path="/setting/email" component={EmailSetting} layout={Default} />
                <UserRoute exact path="/setting/common" component={CommonSetting} layout={Default} />
                <UserRoute exact path="/setting/import-stock" component={ImportStock} layout={Default} />

                <UserRoute exact path="/" component={Dashboard} layout={Default} />
            </Switch>
        </BrowserRouter> 
    </>
  );
}

export default App;
