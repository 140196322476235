import React, { useState, useRef, useContext } from 'react';
import PropTypes from "prop-types"
import { useHistory, withRouter, Link } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { POST_LOGIN, POST_VERIFY_OTP } from '../../helpers/url_helper';
import { setUserSession, getToken } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { CompanyContext } from '../../layouts/App_route';
import {Helmet} from "react-helmet";

const Login = props => {
    let history = useHistory();
    if (getToken()) {
        history.push("/");
    }
    const { company_name, company_logo } = useContext(CompanyContext)
    const simpleValidator = useRef(new SimpleReactValidator());
    const OtpValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [otp_screen, SetOTP_screen] = useState(false);
    const [login_inputs, setInputs] = useState({ email: '', password: '', otp: '', otp_screen: false });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_LOGIN, login_inputs, {});
            if (res_data.status) {
                if (res_data.data.twoFactorEnable > 0) {
                    SetOTP_screen(true);
                    toast(res_data.message);
                } else {
                    setUserSession(res_data.data.token, res_data.data);
                    history.push("/");
                }
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // form submit event
    const verifyOtp = async (event) => {
        event.preventDefault();
        const formValid = OtpValidator.current.allValid()
        if (!formValid) {
            OtpValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_VERIFY_OTP, login_inputs, {});
            if (res_data.status) {
                setUserSession(res_data.data.token, res_data.data);
                history.push("/");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const resend_otp = async () => {
        var res_data = await post(POST_LOGIN, login_inputs, {});
        if (res_data.status) {
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...login_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{(company_name)? company_name : ''} | Forgot Password</title>
            </Helmet>
            <div className='auth_page'>
                <div className='left_bar'>
                    <img alt={company_name} src={company_logo} />
                </div>
                <div className='right_bar'>
                    <div className='form_box'>
                        <h4 >Welcome Back !</h4>
                        <p className="text-muted mt-2">Sign in to continue to {company_name}.</p>
                        {!otp_screen &&
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input name="email" value={login_inputs.email} onChange={handleInputChange} className="form-control" placeholder="Enter Email Address" type="text" />
                                    {simpleValidator.current.message('email', login_inputs.email, 'required|email')}
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input name="password" value={login_inputs.password} onChange={handleInputChange} type="password" className="form-control" placeholder="Enter Password" />
                                    {simpleValidator.current.message('password', login_inputs.password, 'required')}
                                </div>
                                <div className="form-group text-right">
                                    <Link to='/forgot-password' style={{ 'color': '#233b90' }}>Forgot Password</Link>
                                </div>
                                <div className="form-group mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className="btn btn-primary c-btn-primary"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Sign in Securely </button>
                                </div>
                            </form>}
                        {otp_screen &&
                            <form onSubmit={verifyOtp}>
                                <div className="form-group">
                                    <label>Otp</label>
                                    <input name="otp" value={login_inputs.otp} onChange={handleInputChange} className="form-control" placeholder="OTP" type="text" />
                                    {OtpValidator.current.message('otp', login_inputs.otp, 'required')}
                                </div>
                                <div className="form-group text-right">
                                    <Link onClick={resend_otp} to="#" className="text-muted">Resend otp</Link>
                                </div>
                                <div className="form-group mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className="btn btn-primary c-btn-primary"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Verify </button>
                                </div>
                            </form>}
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(Login)
Login.propTypes = {
    history: PropTypes.object,
}
