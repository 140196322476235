import axios from "axios"
import { removeUserSession,getToken } from "./Helper"

const axiosApi = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json',
		'appversion' : '1.1'
		// Authorization: getToken()
	},
	responseType: 'json'
}) 



export async function get(url, config = {}) {
	axiosApi.defaults.headers.Authorization = getToken(); 
	return await axiosApi.get(url, { ...config }).then(response => response.data).catch((error) => {
		if (error.response.status == 401) {
			removeUserSession();
			window.location.href = '/'
		} else {
			return error;
		}
	})
}

export async function post(url, data, config = {}) {
	axiosApi.defaults.headers.Authorization = getToken();
	return axiosApi
		.post(url, { ...data }, { ...config })
		.then(response => response.data)
		.catch((error) => {
			if (error.response.status == 401) {
				removeUserSession();
				window.location.href = '/'
			} else {
				return error;
			}
		})
}

export async function put(url, data, config = {}) {
	axiosApi.defaults.headers.Authorization = getToken();
	return axiosApi
		.put(url, { ...data }, { ...config })
		.then(response => response.data)
		.catch((error) => {
			if (error.response.status == 401) {
				removeUserSession();
				window.location.href = '/'
			} else {
				return error;
			}
		})
}

export async function del(url, config = {}) {
	axiosApi.defaults.headers.Authorization = getToken();
	return await axiosApi
		.delete(url, { ...config })
		.then(response => response.data)
		.catch((error) => {
			if (error.response.status == 401) {
				removeUserSession();
				window.location.href = '/'
			} else {
				return error;
			}
		})
}
