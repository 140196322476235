import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_IMPORT_STOCK } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { CompanyContext } from '../../layouts/Default';
import readXlsxFile from 'read-excel-file'

const ChangePassword = props => {

    const { company_name } = useContext(CompanyContext);
    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({
        brandId: '',
        categoryId: '',
        sizeId: '',
        baseColorId: '',
        punchId: '',
        concept: '',
        designName: '',
        weightPerBox: '',
        batch: '',
        shadeId: '',
        locationId: '',
        preBox: '',
        stdBox: '',
        ecoBox: '',
        mixBox: '',
        totalBox: '',
    });
    const [, forceUpdate] = useState();

    const [optionList, setOptionList] = useState([]);
    const [StockList, setStockList] = useState([]);
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {
        async function get_detaile() {
            setLoader(1);
        }
        get_detaile();
    }, [])


    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            setShowTable(true);
            SetButtonDisabled(false);
        }
    }

    // ImportData
    const ImportData = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.stock = StockList;
            var res_data = await post(POST_IMPORT_STOCK ,form_inputs);
            if (res_data.status) {
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'file') {
            readXlsxFile(event.target.files[0]).then(function (rows) {
                var filteredData = rows.map((row) => row.filter((cell) => cell !== null));
                var filteredData = filteredData.filter(a => !a.every(ax => a == undefined));
                if (filteredData.length > 0) {
                    setOptionList(filteredData[0]);
                    filteredData.splice(0, 1);
                    setStockList(filteredData);
                    console.log(filteredData);
                } else {
                    toast.error('no any data');
                }
            })
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{(company_name) ? company_name : ''} | Import Stock</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>Import Stock</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                {!optionList.length && <div className='row mb-4'>
                                    <div className='col-sm-4'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Select Excel File</label>
                                            <input name="file" onChange={handleInputChange} className="form-control" type="file" accept='.xlsx, .xls' />
                                        </div>
                                    </div>
                                </div>}

                                {optionList.length > 0 && <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Brand</label>
                                            <div className="col-7">
                                                <select className="form-control" name='brandId' onChange={handleInputChange} value={form_inputs.brandId}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                                {simpleValidator.current.message('brand', form_inputs.brandId, 'required')}
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Category</label>
                                            <div className="col-7">
                                                <select className="form-control" name='categoryId' onChange={handleInputChange} value={form_inputs.categoryId}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                                {simpleValidator.current.message('Category', form_inputs.categoryId, 'required')}
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Size</label>
                                            <div className="col-7">
                                                <select className="form-control" name='sizeId' onChange={handleInputChange} value={form_inputs.sizeId}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                                {simpleValidator.current.message('size', form_inputs.sizeId, 'required')}
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Base Color</label>
                                            <div className="col-7">
                                                <select className="form-control" name='baseColorId' onChange={handleInputChange} value={form_inputs.baseColorId}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Punch</label>
                                            <div className="col-7">
                                                <select className="form-control" name='punchId' onChange={handleInputChange} value={form_inputs.punchId}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Concept</label>
                                            <div className="col-7">
                                                <select className="form-control" name='concept' onChange={handleInputChange} value={form_inputs.concept}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Design Name</label>
                                            <div className="col-7">
                                                <select className="form-control" name='designName' onChange={handleInputChange} value={form_inputs.designName}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                                {simpleValidator.current.message('design', form_inputs.designName, 'required')}
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Weight Per Box</label>
                                            <div className="col-7">
                                                <select className="form-control" name='weightPerBox' onChange={handleInputChange} value={form_inputs.weightPerBox}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Batch</label>
                                            <div className="col-7">
                                                <select className="form-control" name='batch' onChange={handleInputChange} value={form_inputs.batch}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Shade</label>
                                            <div className="col-7">
                                                <select className="form-control" name='shadeId' onChange={handleInputChange} value={form_inputs.shadeId}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Location</label>
                                            <div className="col-7">
                                                <select className="form-control" name='locationId' onChange={handleInputChange} value={form_inputs.locationId}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Pre-I</label>
                                            <div className="col-7">
                                                <select className="form-control" name='preBox' onChange={handleInputChange} value={form_inputs.preBox}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">STD-II</label>
                                            <div className="col-7">
                                                <select className="form-control" name='stdBox' onChange={handleInputChange} value={form_inputs.stdBox}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">ECO-III</label>
                                            <div className="col-7">
                                                <select className="form-control" name='ecoBox' onChange={handleInputChange} value={form_inputs.ecoBox}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">MIX</label>
                                            <div className="col-7">
                                                <select className="form-control" name='mixBox' onChange={handleInputChange} value={form_inputs.mixBox}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label for="staticEmail" className="col-5 pt-2">Total Box</label>
                                            <div className="col-7">
                                                <select className="form-control" name='totalBox' onChange={handleInputChange} value={form_inputs.totalBox}>
                                                    <option value=""> Select Column</option>
                                                    {optionList && Object.entries(optionList).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {ShowTable && <div className='row'>
                                    <div className='col-sm-12 border table-fixed' >
                                        <table className='table table-small '>
                                            <thead>
                                                <tr>
                                                    <th>Sr.</th>
                                                    {form_inputs.brandId && <th className='text-left' style={{'minWidth': '100px'}}>Brand</th>}
                                                    {form_inputs.categoryId && <th className='text-left' style={{'minWidth': '100px'}}>Category</th>}
                                                    {form_inputs.sizeId && <th className='text-left' style={{'minWidth': '100px'}}>Size</th>}
                                                    {form_inputs.baseColorId && <th className='text-left' style={{'minWidth': '100px'}}>Base Color</th>}
                                                    {form_inputs.punchId && <th className='text-left' style={{'minWidth': '100px'}}>Punch</th>}
                                                    {form_inputs.concept && <th className='text-left' style={{'minWidth': '100px'}}>Concept</th>}
                                                    {form_inputs.designName && <th className='text-left' style={{'minWidth': '100px'}}>Design Name</th>}
                                                    {form_inputs.weightPerBox && <th className='text-left' style={{'minWidth': '100px'}}>Weight Per Box</th>}
                                                    {form_inputs.batch && <th className='text-left' style={{'minWidth': '100px'}}>Batch</th>}
                                                    {form_inputs.shadeId && <th className='text-left' style={{'minWidth': '100px'}}>Shade</th>}
                                                    {form_inputs.locationId && <th className='text-left' style={{'minWidth': '100px'}}>Location</th>}
                                                    {form_inputs.preBox && <th style={{'minWidth': '100px'}}>PRE-I</th>}
                                                    {form_inputs.stdBox && <th style={{'minWidth': '100px'}}>STD-II</th>}
                                                    {form_inputs.ecoBox && <th style={{'minWidth': '100px'}}>ECO-III</th>}
                                                    {form_inputs.mixBox && <th style={{'minWidth': '100px'}}>MIX</th>}
                                                    {form_inputs.totalBox && <th style={{'minWidth': '100px'}}>Total</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {StockList && Object.entries(StockList).map(([key, value]) => (
                                                    <tr key={key}>
                                                        <td>{++key}</td>
                                                        {form_inputs.brandId && <td className='text-left'>{value[form_inputs.brandId]}</td>}
                                                        {form_inputs.categoryId && <td className='text-left'>{value[form_inputs.categoryId]}</td>}
                                                        {form_inputs.sizeId && <td className='text-left'>{value[form_inputs.sizeId]}</td>}
                                                        {form_inputs.baseColorId && <td className='text-left'>{value[form_inputs.baseColorId]}</td>}
                                                        {form_inputs.punchId && <td className='text-left'>{value[form_inputs.punchId]}</td>}
                                                        {form_inputs.concept && <td className='text-left'>{value[form_inputs.concept]}</td>}
                                                        {form_inputs.designName && <td className='text-left'>{value[form_inputs.designName]}</td>}
                                                        {form_inputs.weightPerBox && <td className='text-left'>{value[form_inputs.weightPerBox]}</td>}
                                                        {form_inputs.batch && <td className='text-left'>{value[form_inputs.batch]}</td>}
                                                        {form_inputs.shadeId && <td className='text-left'>{value[form_inputs.shadeId]}</td>}
                                                        {form_inputs.locationId && <td className='text-left'>{value[form_inputs.locationId]}</td>}
                                                        {form_inputs.preBox && <td>{value[form_inputs.preBox]}</td>}
                                                        {form_inputs.stdBox && <td>{value[form_inputs.stdBox]}</td>}
                                                        {form_inputs.ecoBox && <td>{value[form_inputs.ecoBox]}</td>}
                                                        {form_inputs.mixBox && <td>{value[form_inputs.mixBox]}</td>}
                                                        {form_inputs.totalBox && <td>{value[form_inputs.totalBox]}</td>}
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>}

                                <div className="text-center mt-4">
                                    <Link to="/setting" className='btn btn-danger c-btn-danger mr-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                    {(optionList.length > 0 && !ShowTable) && <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Next</button>}
                                    {ShowTable && <button disabled={ButtonDisabled} type="button" onClick={ImportData} className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Import</button>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
