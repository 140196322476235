import React, { useContext } from 'react';
import { Avatar } from '@material-ui/core';
import { Link } from "react-router-dom";
import { CompanyContext } from '../layouts/Default';

import FeatherIcon from "feather-icons-react";

export default function Header(props) {
    const { company_name, company_logo } = useContext(CompanyContext);

    const handleLangChange = () => {
        props.onChangeHandleMenu();
    }

    return (
        <div className='nav_bar'>
            <div className='nav_bar_left text-center'>
                <img alt={company_name} src={company_logo} width='150px' style={{'maxHeight' : '40px'}} />
            </div>
            <div className='nav_bar_right'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-6'>
                            <span  className='btn-none pointer' style={{'color': '#000'}} onClick={handleLangChange}><FeatherIcon icon="menu" className="mt-10" /></span>
                        </div>
                        <div className='col-6'>
                            <div className='dropdown'>
                                <Avatar alt="Remy Sharp" className='profile' src="/assets/img/avatar.png" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <Link className="dropdown-item" to="/change-password">Change Password</Link>
                                    <Link className="dropdown-item" to="/logout">Logout</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}