import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post,get } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_USER, POST_GET_USER_DETAIL, GET_COUNTRY,GET_STATE,GET_CITY } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';


const BrandModal = props => {

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'userId': 0, 'userName': '', 'email': '', 'password': '', 'mobileNumber': '', 'companyName': '', 'address': '', 'countryId': '', 'stateId': '', 'cityId': '', 'gstNumber': '', 'reference': '' });
    const [, forceUpdate] = useState();

    const [CountryListe, SetCountryListe] = useState([]);
    const [StateListe, SetStateListe] = useState([]);
    const [CityListe, SetCityListe] = useState([]);

    useEffect(() => {
        async function get_detaile() {
            await get_country();
            if (props.editId) {
                var res_data = await post(POST_GET_USER_DETAIL, { 'userId': props.editId }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                    if(res_data.detail.countryId){
                        await get_state(res_data.detail.countryId)
                    }
                    if(res_data.detail.stateId){
                        await get_city(res_data.detail.stateId)
                    }
                } else {
                    toast.error(res_data.message);
                }
            } else {
                setInputs({ 'userId': 0, 'userName': '', 'email': '', 'password': '', 'mobileNumber': '', 'companyName': '', 'address': '', 'countryId': '', 'stateId': '', 'cityId': '', 'gstNumber': '', 'reference': '' });
                SetStateListe([]);
                SetCityListe([]);
            }

            window.document.addEventListener('keyup', (event) => {
                if (event.keyCode === 27) {
                    window.$('#customerModal').modal('hide');
                }
            });
            setLoader(1);
        }
        get_detaile();
    }, [props.editId])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_USER, form_inputs, {});
            if (res_data.status) {
                window.$('#customerModal').modal('hide');
                if (!props.editId) {
                    setInputs({ 'userId': 0, 'userName': '', 'email': '', 'password': '', 'mobileNumber': '', 'companyName': '', 'address': '', 'countryId': '', 'stateId': '', 'cityId': '', 'gstNumber': '', 'reference': '' });
                }
                props.call_back();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // get_country
    const get_country = async (event) => {
        var res_data = await get(GET_COUNTRY, {});
        if (res_data.status) {
            SetCountryListe(res_data.data.country_list);
        } else {
            toast.error(res_data.message);
        }
    }

    const get_state = async(countryId) => {
        var city_data = await get(GET_STATE+'?countryId='+countryId);
        if(city_data.status){
            SetStateListe(city_data.data.state_list);
        }else{
            toast.error(city_data.message);
        }
    }

    const get_city = async(stateId) => {
        var city_data = await get(GET_CITY+'?stateId='+stateId);
        if(city_data.status){
            SetCityListe(city_data.data.city_list);
        }else{
            toast.error(city_data.message);
        }
    }

    // input text change handler
    const handleInputChange = async(event) => {
        event.persist();
        if(event.target.name === 'countryId'){
            form_inputs.countryId = event.target.value;
            form_inputs.stateId = '';
            form_inputs.cityId = '';
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
            await get_state(event.target.value);
        }else if(event.target.name === 'stateId'){
            form_inputs.stateId = event.target.value;
            form_inputs.cityId = '';
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
            await get_city(event.target.value);
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <div className="modal fade" id="customerModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title" id="exampleModalLabel">Customer Master</h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form className="av-invalid" onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Company Name <small>*</small></label>
                                            <input name="companyName" id="companyName" tabIndex={1} value={form_inputs.companyName} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('user name', form_inputs.userName, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">User Name <small>*</small></label>
                                            <input name="userName" id="userName" tabIndex={2} value={form_inputs.userName} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('user name', form_inputs.userName, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Mobile Number <small>*</small></label>
                                            <input name="mobileNumber" tabIndex={3} value={form_inputs.mobileNumber} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('mobile number', form_inputs.mobileNumber, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">GST Number </label>
                                            <input name="gstNumber" tabIndex={4} value={form_inputs.gstNumber} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Password</label>
                                            <input name="password" tabIndex={5} value={form_inputs.password} onChange={handleInputChange} className="form-control" type="password" />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Address</label>
                                            <textarea name="address" rows={2} tabIndex={6} value={form_inputs.address} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group">
                                            <label>Select Countr</label>
                                            <select name="countryId" id="countryId" tabIndex={7} value={form_inputs.countryId} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Countr</option>
                                                {CountryListe && Object.entries(CountryListe).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Select State</label>
                                            <select name="stateId" id='stateId' tabIndex={8} value={form_inputs.stateId} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select State</option>
                                                {StateListe && Object.entries(StateListe).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Select City</label>
                                            <select name="cityId" id='cityId' tabIndex={9} value={form_inputs.cityId} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select City</option>
                                                {CityListe && Object.entries(CityListe).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Reference</label>
                                            <input name="reference" tabIndex={10} value={form_inputs.reference} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel (Esc)</button>
                                <button type='submit' disabled={ButtonDisabled} className="btn btn-sm btn-primary c-btn-primary">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(BrandModal)
