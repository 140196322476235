import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { POST_SEARCH_PRODUCT } from '../../helpers/url_helper';

const BrandModal = props => {

    const [suggestion, setsuggestion] = useState([]);
    const [activeOption, setActiveOption] = useState(-1);
    const [showOptions, setshowOptions] = useState(false);
    const [form_inputs, setInputs] = useState({ 'itemId': 0, 'designName': '', sizeId : 0,brandId : 0 });

    useEffect(() => {
        async function get_detaile() {
            window.document.addEventListener('keyup', (event) => {
                if (event.keyCode === 27) {
                    window.$('#searchDesigneModal').modal('hide');
                    setTimeout(() => {
                        window.$("#batch").focus();
                    }, 500);
                }
            });
            setInputs({ 'itemId': 0, 'designName': '' , sizeId : props.product_inputs.sizeId , brandId : props.product_inputs.brandId});
        }
        
        get_detaile();
    }, [props])

    const onChange = async (e) => {
        var search_value = e.currentTarget.value;
        setActiveOption(-1);
        var res_data = await post(POST_SEARCH_PRODUCT, { sizeId: form_inputs.sizeId, brandId: form_inputs.brandId, search: search_value });
        if (res_data.status) {
            setsuggestion(res_data.data.product_list);
        }
        setInputs(inputs => ({ ...form_inputs, ['designName']: search_value }));
        setshowOptions(true);
    };

    const onKeyDown = async (e) => {
        if (e.keyCode === 13) {
            if (showOptions && activeOption >= 0) {
                form_inputs.itemId = suggestion[activeOption].itemId;
                form_inputs.designName = suggestion[activeOption].designName;
                setInputs(inputs => ({ ...form_inputs, ['designName']: suggestion[activeOption].designName }));
                setshowOptions(false);
                setActiveOption('');
                props.call_back(form_inputs);
                window.$('#searchDesigneModal').modal('hide');
            } else {
                setshowOptions(false);
            }
        } else if (e.keyCode === 38) {
            if (activeOption === 0) {
                return;
            }
            setActiveOption(parseInt(activeOption) - 1)
        } else if (e.keyCode === 40) {
            if (activeOption === suggestion.length - 1) {
                return;
            }
            setActiveOption(parseInt(activeOption) + 1)
        }
    };

    const select_serch = async (index,select) => {
        form_inputs.itemId = select.itemId
        setInputs(inputs => ({ ...form_inputs, ['designName']: select.designName }));
        setshowOptions(false);
        setActiveOption(index);
    }

    return (
        <>
            <div className="modal fade" id="searchDesigneModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Item Master</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group search_box mb-3">
                                            <input type="text" id='designSearch' onChange={onChange} onKeyDown={onKeyDown} value={form_inputs.designName} className="form-control" placeholder="Search design" />
                                            <div className='table-fixed'>
                                                <table className='table table-small suggestion mt-3'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-left'>Design Name</th>
                                                            <th className='text-left'>Category</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {suggestion.map((optionName, index) => {
                                                            var className = (index === activeOption) ? 'active' : '';
                                                            return (
                                                                <tr className={className} key={index} onClick={select_serch.bind(this, index,optionName)}>
                                                                    <td className='text-left'>{optionName.designName}</td>
                                                                    <td className='text-left'>{optionName.categoryName}</td>
                                                                </tr>
                                                            );
                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel (Esc)</button>
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(BrandModal)
