import React,{ useState, useRef, useContext } from 'react';
import {withRouter } from "react-router-dom";
import {post} from '../../helpers/api_helper';
import {POST_CHANGE_PASSWORD} from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import {Helmet} from "react-helmet";
import { CompanyContext } from '../../layouts/Default';

const ChangePassword = props => {

    const { company_name } = useContext(CompanyContext);
	const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ old_password: '', password: '', c_password: '' });
    const [, forceUpdate] = useState();
    const [PasswordShow, SetPasswordShow] = useState(false);
    const [CPasswordShow, SetCPasswordShow] = useState(false);

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var md5 = require('md5');
            form_inputs.old_password = md5(form_inputs.old_password);
            form_inputs.password = md5(form_inputs.password);
            form_inputs.c_password = md5(form_inputs.c_password);
            var res_data = await post(POST_CHANGE_PASSWORD, form_inputs, {});
            if (res_data.status) {
                setInputs({ old_password: '', password: '', c_password: '' });
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    // GeneratePassword
    const GeneratePassword = () => {
        var generator = require('generate-password');
        var password = generator.generate({
            length: 10,
            numbers: true,
            symbols: false,
            uppercase: false,
            excludeSimilarCharacters: false
        });
        setInputs(inputs => ({ ...form_inputs, ['password']: password }));
    }

    const PasswordShowHide = (name) => {
        console.log(name);
        if (name === 'password') {
            if (PasswordShow) {
                SetPasswordShow(false);
            } else {
                SetPasswordShow(true);
            }
        } else {
            if (CPasswordShow) {
                SetCPasswordShow(false);
            } else {
                SetCPasswordShow(true);
            }
        }
    }

    return (
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <title>{(company_name)? company_name : ''} | Change Password</title>
            </Helmet>
            
            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>Change Password</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label">Enter Old Password</label>
                                    <input name="old_password" value={form_inputs.old_password} onChange={handleInputChange} className="form-control" type="password" />
                                    {simpleValidator.current.message('old password', form_inputs.old_password, 'required')}
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Enter New Password</label>
                                    <div className='row'>
                                        <div className='col-sm-8'>
                                            <div className="input-group">
                                                <input name="password" value={form_inputs.password} onChange={handleInputChange} className="form-control" type={PasswordShow ? 'text' : 'password'} />
                                                <div className="input-group-text pt-1" onClick={PasswordShowHide.bind(this, 'password')}>
                                                    {!PasswordShow && <i className="fa fa-eye align-middle"></i>}
                                                    {PasswordShow && <i className="fa fa-eye-slash"></i>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <button type="button" onClick={GeneratePassword} className="btn btn-primary">Generate Password</button>
                                        </div>
                                    </div>
                                    {simpleValidator.current.message('password', form_inputs.password, 'required')}
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <div className='row'>
                                        <div className='col-sm-8'>
                                            <div className="input-group">
                                                <input name="c_password" value={form_inputs.c_password} onChange={handleInputChange} className="form-control"  type={CPasswordShow ? 'text' : 'password'} />
                                                <div className="input-group-text pt-1" onClick={PasswordShowHide.bind(this, 'c_password')}>
                                                    {!CPasswordShow && <i className="fa fa-eye align-middle"></i>}
                                                    {CPasswordShow && <i className="fa fa-eye-slash"></i>}
                                                </div>
                                            </div>
                                            {form_inputs.password && simpleValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Change Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>    
    );
}
export default withRouter(ChangePassword)
