import React, {useCallback, useState, useImperativeHandle, useRef, forwardRef, useEffect } from 'react';
import { post } from '../../helpers/api_helper';
import { POST_SELECT_ITEM_PRODUCT } from '../../helpers/url_helper';

export default forwardRef((props, ref) => {
    const [designList, setDesignList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    var varActiveOption = 0;
    const var_ref = useRef({ designList: [] });

    const [active_Option, set_Active_Option] = useState(0);
    const [formInputs, set_Inputs] = useState({ itemId: 0, sizeId: 0, brandId: 0, stockId: 0 });

    const handleKeyPress = useCallback((event) => {
        onKeyDown(event);
    });

    useEffect(() => {
        async function get_detaile() {
            set_Inputs({
                itemId: props.product_inputs.itemId,
                sizeId: props.product_inputs.sizeId,
                brandId: props.product_inputs.brandId,
                stockId: props.product_inputs.stockId,
                batch: props.product_inputs.batch,
                shadeId: props.product_inputs.shadeId,
                locationIdshadeId: props.product_inputs.locationId,
            });
        }

        get_detaile();
    }, [props.product_inputs.itemId])

    useImperativeHandle(ref, () => ({
        async getDetaile() {
            set_Active_Option(0);
            var res_data = await post(POST_SELECT_ITEM_PRODUCT, { sizeId: formInputs.sizeId, brandId: formInputs.brandId, itemId: formInputs.itemId });
            if (res_data.status) {
                setDesignList(res_data.data.design_list);
                var_ref.current.designList = res_data.data.design_list;
                if (var_ref.current.designList.length > 0) {
                    setOrderList(var_ref.current.designList[0].order_list);
                }
            }
            document.addEventListener('keyup', handleKeyPress);
        }
    }))

    const onKeyDown = async (event) => {
        if (window.$('#selectItomeModal').is(':visible')) {
            if (event.keyCode === 40 || event.keyCode === 38) {
                if (event.keyCode === 40 && var_ref.current.designList.length - 1 > varActiveOption) {
                    varActiveOption = (parseInt(varActiveOption) + parseInt(1));
                    set_Active_Option(varActiveOption);
                    setOrderList(var_ref.current.designList[varActiveOption].order_list);
                } else if (event.keyCode === 38 && varActiveOption > 0) {
                    varActiveOption = (parseInt(varActiveOption) - parseInt(1));
                    set_Active_Option(varActiveOption);
                    setOrderList(var_ref.current.designList[varActiveOption].order_list);
                }
            } else if (event.keyCode === 13) {
                formInputs.stockId = var_ref.current.designList[varActiveOption].stockId;
                formInputs.batch = var_ref.current.designList[varActiveOption].batch;
                formInputs.shadeId = var_ref.current.designList[varActiveOption].shadeId;
                formInputs.locationId = var_ref.current.designList[varActiveOption].locationId;
                set_Inputs(formInputs);
                props.call_back(formInputs);
                window.$('#selectItomeModal').modal('hide');
                window.$("#preBox").focus();
                document.removeEventListener('keyup', handleKeyPress);
            }else if(event.keyCode === 27){
                formInputs.stockId = 0;
                formInputs.batch = '';
                formInputs.shadeId = 0;
                formInputs.locationId = 0;
                set_Inputs(formInputs);
                props.call_back(formInputs);
                window.$('#selectItomeModal').modal('hide');
                window.$("#preBox").focus();
                document.removeEventListener('keyup', handleKeyPress);
            }
        }
    };

    const select_serch = async (index, select) => {
        set_Active_Option(index);
    }

    return (
        <>
            <div className="modal fade" id="selectItomeModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="exampleModalLabel">Select Batch Shade Location Item</h5>
                            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-12 border table-fixed' >
                                    <h6>Order Detailes</h6>
                                    <table className='table table-small '>
                                        <thead>
                                            <tr>
                                                <th>Order No.</th>
                                                <th>Customer Name</th>
                                                <th>Ref Party</th>
                                                <th>PRE-I</th>
                                                <th>STD-II</th>
                                                <th>ECO-III</th>
                                                <th>MIX</th>
                                                <th>Total</th>
                                                <th>Date</th>
                                                <th>City</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderList.map((optionName, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{optionName.orderId}</td>
                                                        <td>{optionName.companyName}</td>
                                                        <td>{optionName.reference}</td>
                                                        <td>{optionName.preBox}</td>
                                                        <td>{optionName.stdBox}</td>
                                                        <td>{optionName.ecoBox}</td>
                                                        <td>{optionName.mixBox}</td>
                                                        <td>{optionName.totalBox}</td>
                                                        <td>{optionName.orderDate}</td>
                                                        <td>{optionName.cityName}</td>
                                                    </tr>
                                                );
                                            })}
                                            {!orderList.length && <tr>
                                                <td colSpan={10} className='text-center'>No any order</td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-sm-12 border table-fixed' >
                                    <h6>Stock Detailes</h6>
                                    <table className='table table-small suggestion'>
                                        <thead>
                                            <tr>
                                                <th>Batch</th>
                                                <th>Shade</th>
                                                <th>Location</th>
                                                <th>PRE-I</th>
                                                <th>STD-II</th>
                                                <th>ECO-III</th>
                                                <th>MIX</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {designList.map((optionName, index) => {
                                                var className = (index === active_Option) ? 'active' : '';
                                                return (
                                                    <tr className={className} key={index} onClick={select_serch.bind(this, index, optionName)}>
                                                        <td>{optionName.batch}</td>
                                                        <td>{optionName.shadeName}</td>
                                                        <td>{optionName.locationName}</td>
                                                        <td>{optionName.preBox}</td>
                                                        <td>{optionName.stdBox}</td>
                                                        <td>{optionName.ecoBox}</td>
                                                        <td>{optionName.mixBox}</td>
                                                        <td>{optionName.totalBox}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel (Esc)</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
})
// export default withRouter(ChildComp)