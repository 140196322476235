import React from 'react';
import { NavLink } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
export default function Header() {

    return (
        <div className='left_nav_bar'>
            <ul className='sidebar-menu'>
                <li>
                    <NavLink exact to="/" className="has-arrow">
                        <FeatherIcon icon="home" width="22" />
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                {/* <li>
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                        <FeatherIcon icon="users" width="22" />
                        <span>User</span>
                    </a>
                    <ul className="collapse list-unstyled" id="homeSubmenu">
                        <li>
                            <NavLink to="/users">Users</NavLink>
                        </li>
                    </ul>
                </li> */}
                <li>
                    <NavLink to="/customer" >
                        <FeatherIcon icon="users" width="22" />
                        <span>Customer</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/order" >
                        <FeatherIcon icon="file-text" className="mt-1" width="22" />
                        <span>Order</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dispatched" >
                        <FeatherIcon icon="file-text" className="mt-1" width="22" />
                        <span>Dispatched</span>
                    </NavLink>
                </li>
                <li>
                    <a href="#StockMaster" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                        <i className="fa fa-industry" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Stock</span>
                    </a>
                    <ul className="collapse list-unstyled" id="StockMaster">
                        <li><NavLink to="/production">Production</NavLink></li>
                        <li><NavLink to="/stock">Stock</NavLink></li>
                        <li><NavLink to="/shade">Shade</NavLink></li>
                        <li><NavLink to="/location">Location</NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink to="/stock-view" >
                        <FeatherIcon icon="file-text" className="mt-1" width="22" />
                        <span>Stock View</span>
                    </NavLink>
                </li>
                <li>
                    <a href="#ItemMaster" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                        <i className="fa fa-cubes" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Item Master</span>
                    </a>
                    <ul className="collapse list-unstyled" id="ItemMaster">
                        <li><NavLink to="/item-master">Item Master</NavLink></li>
                        <li><NavLink to="/brand">Brand</NavLink></li>
                        <li><NavLink to="/categories">Categories</NavLink></li>
                        <li><NavLink to="/size">Size</NavLink></li>
                        <li><NavLink to="/base-color">Base Color</NavLink></li>
                        <li><NavLink to="/punch">Punch</NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink exact to="/setting" className="has-arrow">
                        <FeatherIcon icon="settings" width="22" className="mt-1" />
                        <span>Setting</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}